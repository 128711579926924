import React from 'react';
import VariantsEnum from '@/enums/VariantsEnum';
import getId from '@/helpers/getId';
import Error from '@/UI/form-control/error';
import styles from './select.module.css';

type IValue = {
    value: string,
    label: string
}

export type IProps = {
    id?: string
    name: string
    label: string
    defaultLabel?: string
    value?: string
    values: IValue[]
    variant?: VariantsEnum
    required?: boolean
    disabled?: boolean
    multiple?: boolean
    error?: string
    className?: string
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
    onBlur?: (event: React.ChangeEvent<HTMLSelectElement>) => void
}

const Select = React.forwardRef<HTMLSelectElement, IProps>(({
    id,
    name,
    label,
    defaultLabel,
    value,
    values,
    variant,
    required,
    disabled,
    multiple,
    error,
    className = '',
    onChange,
    onBlur,
}, ref) => (
    <div className={`${styles.control} ${disabled ? styles.disabled : ''} ${variant ? styles[variant] : ''} ${error ? styles[VariantsEnum.danger] : ''} ${className}`}>
        <label
            htmlFor={id || name}
            className={styles.label}
        >
            {label}
        </label>
        <div className={styles.wrapper}>
            {required && (<sup className={styles.required}>*</sup>)}
            <select
                ref={ref}
                id={id || name}
                name={name}
                value={value}
                disabled={disabled}
                multiple={multiple}
                className={styles.select}
                aria-errormessage={`err_${id || name}`}
                aria-invalid={!!error}
                data-testid="component-select"
                onChange={onChange}
                onBlur={onBlur}
            >
                {(defaultLabel && !value) && (
                    <option value={defaultLabel}>
                        {defaultLabel}
                    </option>
                )}

                {values?.length > 0 && values.map(item => (
                    <option
                        key={`${getId(4)}-${item.value}`}
                        value={item.value}
                        className={styles.option}
                    >
                        {item.label}
                    </option>
                ))}
            </select>
            <span className={styles.outline} />
        </div>

        {error && <Error id={`err_${id || name}`}>{error}</Error>}
    </div>
));

Select.displayName = 'Select';

export default Select;
